import React from 'react'
import './images-swiper-a6.css'
import Swiper from 'swiper'
import PropTypes from 'prop-types'



export default class ImagesSwiperA6 extends React.Component {
  static propTypes={
    imageUrls:PropTypes.array.isRequired
  }
  componentDidMount() {
    var galleryThumbs = new Swiper('.gallery-thumbs', {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    })
    var galleryTop = new Swiper('.gallery-top', {
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: galleryThumbs
      }
    })

  }

  render(){
    const imagesBig=this.props.imageUrls.map((image,index)=>
      <div key={index} className="swiper-slide"  style={{backgroundImage:'url('+image+')'}} >
    </div>)
    const imagesSmall=this.props.imageUrls.map((image,index)=>
      <div key={index} className="swiper-slide"  style={{backgroundImage:'url('+image+')'}} >
    </div>)
    return (
      <div className="swiper-a6">
        <div className="swiper-container gallery-top"  >
          <div className="swiper-wrapper">
            {imagesBig}
          </div>
          <div className="swiper-button-next swiper-button-white"></div>
          <div className="swiper-button-prev swiper-button-white"></div>
        </div>
        <div className="swiper-container gallery-thumbs" >
          <div className="swiper-wrapper">
            {imagesSmall}
          </div>
        </div>
      </div>
    )
  }


}